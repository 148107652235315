import { Link } from '@remix-run/react';
import { IconAlert } from '../Icons/index.tsx';

export const ErrorMessage = ({
	title,
	message,
}: {
	title?: string;
	message: string;
}) => {
	return (
		<div className="error-container">
			<IconAlert className="error-container--icon" />
			<div className="error-container--content">
				{title ? <h1>{title}</h1> : null}
				<p>{message}</p>
				<p>
					Head <Link to="/">home</Link> if you've lost your bearings,
					or <Link to="/contact">contact us</Link> if you need some
					help
				</p>
			</div>
		</div>
	);
};
